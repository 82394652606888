.sidecard {
    position: relative;
    margin-top: 125px;
}

.image-container {
    position: relative;
    top: -125px;
    /* filter: drop-shadow(10px 10px 10px rgb(145, 145, 145)); */
    /* width: 250px; */
    height: 310px;
}

.sidecard-image {
    max-height: 250px;
}

.sidecard-mascot {
    max-height: 60px;
}

.sidecard-content-container {
    position: relative;
    top: -93.75px;
}

.sidecard-bottom-container {
    position: relative;
    top: -62.5px;
}


.sidecard-subtitle {
    background-image: var(--hover-gradient);
}