:root {
    --wireframe-color: lightblue;
}

#root {
    min-height: 100vh;
}

body {
    min-height: 100vh;
    background: rgba(0, 0, 0);
}

.app-div {
    /* background: rgba(0, 0, 0); */
}

.background-div {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    /* background: url(../public/dots.svg), radial-gradient(var(--secondary-background-color) 0%, var(--background-color) 100%); */
    /* background: radial-gradient(var(--secondary-background-color) 0%, var(--background-color) 100%); */
    background: var(--background-color);
}

.card-color {
    background-color: var(--card-color) !important;
}

.primary-color {
    background-color: var(--primary-color) !important;
}


.background-red {
    background-color: var(--red) !important;
}

.background-green {
    background-color: var(--green) !important;
}

.background-yellow {
    background-color: var(--yellow) !important;
}

.background-blue {
    background-color: var(--blue) !important;
}

.background-magenta {
    background-color: var(--magenta) !important;
}

.background-cyan {
    background-color: var(--cyan) !important;
}


.text-color {
    color: var(--text-color);
}

.rounded-border {
    border-radius: 10px;
}

.wireframe {
    border: 1px solid var(--wireframe-color);
}


.fixed-col-parent {
    position: relative;
}

.fixed-col {
    position: -webkit-sticky;
    position: sticky;
    top: 173px;
}

.drop-shadow {
    filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.3));
}

.drop-shadow-small {
    filter: drop-shadow(6px 6px 5px rgba(0, 0, 0, 0.3));
}

.hr-border-white {
    border-top: 1px solid white;
}