.menu-button {
    /* height: 80px; */
    /* width: 70px; */
    height: 60px;
    border-radius: 10px;
    display: block;
    color: inherit;
    text-decoration: inherit;
    margin-top: 20px;
}

.menu-button-icon {
    margin-top: 5px;
    height: 30px;
    width: 30px;
    filter: invert(99%) sepia(12%) saturate(7483%) hue-rotate(182deg) brightness(128%) contrast(100%);
}

.menu-button:hover {
    background-image: var(--accent-gradient);
}

.menu-button-selected {
    background-image: var(--accent-gradient);
}