.div-line {
    /* background-image: var(--accent-gradient); */
    background-color: var(--accent-color);
    height: 2px;
    width: 100%;
}

.hobby-icon {
    max-width: 100px;
    margin: 20px;
}