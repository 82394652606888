.timeline-icon {
    height: 30px;
    width: 30px;
    filter: invert(99%) sepia(12%) saturate(7483%) hue-rotate(182deg) brightness(128%) contrast(100%);
    margin-top: auto !important;
    margin-bottom: auto !important;
    display: block;
    -ms-transform: translateY(40%);
    transform: translateY(40%);
}

@media only screen and (max-width: 1170px) {
    .timeline-icon {
        height: 20px;
        width: 20px;
        -ms-transform: translateY(37%);
        transform: translateY(37%);
    }
}