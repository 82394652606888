.small-button {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: block;
    color: var(--accent-color);
}

.small-button-icon {
    margin-top: 10px;
    height: 20px;
    width: 20px;
    filter: invert(99%) sepia(12%) saturate(7483%) hue-rotate(182deg) brightness(128%) contrast(100%);
    /* filter: invert(53%) sepia(45%) saturate(654%) hue-rotate(307deg) brightness(93%) contrast(89%); */
}

.small-button:hover {
    background-image: var(--accent-gradient) !important;
}