.diagonal-image-card {
    -webkit-animation: cd-bounce-2 .6s;
    animation: cd-bounce-2 .6s;
}


@media only screen and (max-width: 1400px) {
    .diagonal-image-card-content {
        position: relative;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        top: -40px;
    }
}

@media only screen and (min-width: 768px) {
    .diagonal-image-card-content {
        top: -80px;
    }
}


@media only screen and (min-width: 1400px) {
    .diagonal-image-card {
        position: relative;
    }


    .diagonal-image-container {
        max-width: 600px;
        max-height: 336px;
        position: relative;
    }

    .diagonal-image-card-content {
        width: 400px;
        height: 200px;
        position: relative;
    }

    .diagonal-image-container.corner_0 {
        top: 0px;
        left: 0px;
    }

    .diagonal-image-card-content.corner_0 {
        top: -80px;
        left: 400px;
    }

    .diagonal-image-container.corner_1 {
        top: 0px;
        left: 200px;
    }

    .diagonal-image-card-content.corner_1 {
        top: -80px;
        left: 0px;
    }

    .diagonal-image-container.corner_2 {
        top: 80px;
        left: 200px;
    }

    .diagonal-image-card-content.corner_2 {
        top: -336px;
        left: 0px;
    }

    .diagonal-image-container.corner_3 {
        top: 80px;
        left: 0px;
    }

    .diagonal-image-card-content.corner_3 {
        top: -336px;
        left: 400px;
    }
}